import React from 'react';
import { View, ImageBackground, Image, Text, StyleSheet, TouchableOpacity, StatusBar } from 'react-native';

const App = () => {
  const handleRedirect = () => {
    window.location.href = 'https://onlyfans.com/nextdoornataile/c20';
  };

  return (
    <ImageBackground
      source={require('./workk.jpg')}
      style={styles.container}
    >
       <View style={styles.overlay}>
      <View style={styles.centerContainer}>

       
        <Text style={styles.text}> NEXT DOOR NATALIE</Text>

        <View style={styles.infoContainer}>
          <Text style={styles.infoText}>Your new favourite 18 year old girlfriend</Text>
          <Text style={styles.infoText}>😈</Text>
        </View>

        <TouchableOpacity onPress={handleRedirect} style={styles.button}>
          <View style={styles.newdiv}>
          <Text style={styles.buttonText}>&#x1F970; OnlyFans &#x1F970;</Text>
          </View>
        </TouchableOpacity>
      </View>

      <StatusBar style="auto" />
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
  centerContainer: {
    alignItems: 'center',
  },

  overlay: {
    alignItems: 'center',
    justifyContent:'center',
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black color
  },

  image: {
    width: 200,
    height: 200,
    borderRadius: 100,
    marginBottom: 20,
    marginTop: -140,
  },
  text: {
    color: 'white',
    fontSize: 24,
    fontWeight:'bold'
  },
  infoContainer: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'black',
    padding: 18,
    marginTop: 10,
    borderRadius: 70,
  },
  infoText: {
    color: 'white',
    fontSize: 17,
    marginTop: 3,
  },
  button: {
    //backgroundColor: '#FF00FF',
    backgroundColor: 'transparent',
    opacity:0.8,
    padding:19,
    marginTop: 20,
    borderRadius: 50,
    //opacity: 0.6,
    borderWidth: 4,
    borderColor:'white'
    
  },
  newdiv: {
    backgroundColor: 'transparent',
  },

  buttonText: {
    
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    //opacity: 0.8,
   // backgroundColor: '#FF00FF',
    borderRadius: 50,

  },
});

export default App;
